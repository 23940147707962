@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;

  & &__img {
    margin-right: 0;
    margin-bottom: 20px;

    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  & &__profile {
    margin-left: 0;

    @include media_desktop {
      margin-left: 16px;
    }
  }

  & &__title {
    .ec-headingTitle {
      font-size: 18px;

      @include media_desktop {
        font-size: 22px;
      }
    }
  }

  & &__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px dotted #ccc;
  }

  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }

  & &__priceRegular {
    padding-top: 14px
  }

  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }

  & &__price {
    color: var(--c-primary);
    font-size: 22px;
    border-bottom: 1px solid #9FA0A0;
    margin-bottom: 40px;
    padding-bottom: 30px;
  }

  & &__code {
    padding: 14px 0;
  }

  & &__category {
    padding: 20px 0;

    a {
      color: var(--c-white);
      background-color: var(--c-red);
      padding: 5px 15px;

      @include mq(sp) {
        font-size: 14px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  & &__actions {
    padding: 14px 0;

    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;

        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }

  & &__btn {
    width: 100%;
    margin-bottom: 10px;

    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px;
    }
  }

  & &__description {
    margin-top: 50px;
    margin-bottom: 16px;

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 14px;

      @include mq(sp) {
        font-size: 12px;
      }

      tr {
        border-bottom: 1px solid #9FA0A0;

        &:first-of-type {
          border-top: 1px solid #9FA0A0;
        }

        th {
          width: 30%;
          padding: 15px;
          background-color: #F1F0E6;
          border-right: 1px solid #9FA0A0;
        }

        td {
          padding: 15px;
        }
      }
    }
  }

}