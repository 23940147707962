@import "../mixins/media";

/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;

  max-width: 1000px;
  padding: 0;

  & &__infos {
    @include container;
    display: flex;
    border-top: 0;
    margin-bottom: 50px;
    padding-top: 5px;
    flex-direction: column;

    @include media_desktop {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #ccc;
      padding-top: 16px;
      flex-direction: row;
    }
  }

  & &__counter {
    margin-bottom: 16px;
    width: 100%;

    @include media_desktop {
      margin-bottom: 0;
      width: 50%;
    }
  }

  & &__actions {
    text-align: right;
    width: 100%;

    @include media_desktop {
      width: 50%;
    }
  }


}