@import "../mixins/media";
@import "../mixins/projects";

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  background: var(--c-red);
  color: var(--c-white);

  @include media_desktop() {
    padding-top: 40px;
  }

  & &__inner {
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link {
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 40px 0 60px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 50px 0 80px;
  }

  & &__logo {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a {
      font-size: 22px;
      color: inherit;

      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }

  & &__copyright {
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}


.front_page {
  .footer {
    margin-top: 0 !important;
  }
}


.footer {
  background-color: var(--c-red);
  color: var(--c-white);
  margin-top: 100px;

  @include mq(tb) {
    margin-top: 30px;
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1150px;
    margin: 0 auto;
    padding: 50px 20px;

    @include mq(tb) {
      flex-direction: column;
    }
  }

  &Nav {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    list-style: none;
    padding: 0;

    @include mq(tb) {
      gap: 30px;
      flex-direction: column;
      margin-bottom: 50px;
    }

    &__link {
      @include mq(sp) {
        text-align: center;
      }

      a {
        color: var(--c-white);
        font-size: 14px;
      }
    }
  }

  .copyright {
    padding-bottom: 20px;
    text-align: center;
  }
}