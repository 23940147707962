@import url('../../../../../node_modules/normalize.css/normalize.css');
@import "mixins/variables";
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

:root {
  --c-primary: #000000;
  --c-black: #000;
  --c-white: #FFF;
  --c-red: #881D1F;
  --c-orange: #FF7D19;

  --w-max: 1300px;

  --f-primary: "Noto Sans JP", sans-serif;
  --f-primary-en: "Lora", serif;

  --z-index-nav: 9999;
  --z-index-contents: 9998;
}

$breakpoints: (
  'xs': (max-width: 0),
  'sp': (max-width: 576px),
  'tb': (max-width: 768px),
  'lg-min': (max-width: 1045px),
  'lg': (max-width: 1300px),
  'xl': (max-width: 1450px),
  'xxl': (max-width: 1920px),
  'pc': (min-width: 1920px),
  ) !default;


// 通常のフォント
@mixin fz($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: calc($size/$base * 0.625) + rem;
}

//clamp設定
@function clampVw($min, $size, $max, $vp: 1920) {
  $min: $min * 0.1rem;
  $max: $max * 0.1rem;
  $num: calc(100 / $vp) * 1vw;
  $size_vw: $num * $size;
  @return clamp($min, $size_vw, $max
);
}


@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // マップ型で定義されていない値が呼び出された時はエラーを返す
  @else {
    @error "指定されたブレークポイントは定義されていません。"+"指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
}

@mixin hover {
  @media (hover: hover) {
    &:where(:any-link, :enabled, summary):hover {
      @content;
    }
  }
}


.pcnone {
  display: none;

  @include mq(sp) {
    display: block
  }
}

.spnone {
  @include mq(sp) {
    display: none;
  }
}


html {
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}


body {
  color: var(--c-primary);
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: var(--f-primary);
  font-weight: 400;
  font-style: normal;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 2px;

  @include mq(tb) {
    line-height: 1.5;
  }

  @include mq(sp) {
    font-size: 14px;
  }

  &.visibility {
    visibility: hidden;
  }
}

a {
  color: var(--c-primary);
  text-decoration: none;
}

a {
  transition: .5s all;

  @include hover {
    opacity: .7;
  }
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/12.9.calendar";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";

@import "sections/components";


.jp {
  font-family: var(--f-primary);
}

.en {
  font-family: var(--f-primary-en);
  font-weight: 500;
}

.heading {
  margin-bottom: 50px;

  @include mq(sp) {
    margin-bottom: 20px;
  }

  h2 {
    margin: 0;
    line-height: 1.7;
    text-align: center;

    .en {
      font-size: 36px;
    }

    .jp {
      font-size: 15px;
    }
  }
}

#page_homepage {
  .courseTicket__contentsList {
    padding: 0 5%;
  }
}

#page_homepage,
#page_product_list {
  .header {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.header {
  width: 100%;
  padding: 0 20px;

  &__inner {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 30px auto
  }

  &__right {
    @include mq(tb) {
      display: none;
    }
  }

  &Nav {
    display: flex;
    justify-content: space-between;
    width: 250px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        font-size: 14px;

        p {
          margin: auto;
        }
      }
    }
  }
}

.main_visual {
  .item {
    margin: 0;

    img {
      max-width: 100%;
      height: auto;
      margin-top: 0;
    }

  }
}

.categoryChoice {
  padding-top: 50px;

  @include mq(sp) {
    padding-top: 30px;
  }

  &__contents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
    padding: 0 5% 100px;

    @include mq(sp) {
      margin-top: 20px;
      padding: 0 5% 70px;
    }

    &Item {
      max-width: 420px;
      width: 100%;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 20px;
        border: 1px solid var(--c-red);
        border-radius: 100px;

        @include mq(sp) {
          gap: 25px;
          padding: 13px;
        }

        @include hover {
          color: var(--c-white);
          background-color: var(--c-red);
          border: 1px solid var(--c-white);

          .icon-svg {
            fill: var(--c-white);
          }
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .en {
          font-size: 35px;

          @include mq(tb) {
            font-size: 20px;
          }
        }

        .jp {
          font-size: 14px;
        }
      }

      &__icon {
        @include mq(tb) {
          width: 45px;

          svg {
            width: 100%;
          }
        }

        .icon-svg {
          transition: fill 0.5s;
          fill: #881d1f;
          stroke-width: 0px;
        }
      }
    }
  }
}

.ticketSection {
  width: 99.5vw;
  margin: 0 calc(50% - 50vw);
  padding: 0 0 100px;

  @include mq(tb) {
    width: 100vw;
  }

}

.courseTicket {
  &__visual {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 50px;
    padding: 140px 20px;

    @include mq(tb) {
      padding: 50px 5%;
    }

    &.bg01 {
      background-image: url(../img/top/courseTicketTitle.jpg);
    }

    &.bg02 {
      background-image: url(../img/top/courseTicketTitle_2.jpg);
    }

    .heading {
      max-width: 1150px;
      margin: 0 auto;

      h2 {
        width: 420px;

        @include mq(tb) {
          width: 100%;
        }

        .en {
          font-size: 35px;

          @include mq(tb) {
            font-size: 32px;
          }
        }

        .jp {
          font-size: 15px;

          @include mq(tb) {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__contents {
    &__desc {
      margin: 0 0 50px;
      padding: 0 5%;

      p {
        text-align: center;

        @include mq(tb) {
          text-align: left;
        }

        br {
          @include mq(tb) {
            display: none;
          }
        }
      }
    }

    &List {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      max-width: 1000px;
      margin: 0 auto;

      @include mq(sp) {
        flex-direction: column;
        gap: 50px;
      }
    }

    &Item {
      width: 31%;

      @include mq(tb) {
        width: 30%;
      }

      @include mq(sp) {
        width: 80%;
        margin: 0 auto;
      }

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__img {
        img {
          width: 100%;
        }
      }

      &__title {
        margin-bottom: 20px;

        h2,
        h3 {
          font-size: 18px;
          line-height: 1.5;

          @include mq(sp) {
            font-size: 14px;
          }

          span {
            color: var(--c-orange);
          }
        }
      }

      &__price {
        border-bottom: 1px solid var(--c-primary);
        margin-top: auto;
        padding-bottom: 10px;
        text-align: right;

        span {
          font-size: 14px;
        }
      }
    }

    .infoLink {
      display: inline-block;
      margin-left: auto;
      margin-top: 20px;
      padding: 5px 5%;
      color: var(--c-red);
      border: 1px solid var(--c-red);
      border-radius: 100px;
      font-weight: bold;
    }
  }

  .homeCare {
    .heading {
      color: var(--c-white);

      h2 {
        margin-left: auto;
      }
    }
  }

  .linkBox {
    max-width: 1000px;
    margin: 50px auto 0;
    padding-right: 5%;
    text-align: right;

    a {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -70px;
        top: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 1px;
        background-color: var(--c-primary);

        @include mq(sp) {
          width: 50px;
        }
      }
    }
  }
}


.newsSection {
  background-color: #F1F0E6;
  padding: 100px 5%;

  &__inner {
    max-width: 1150px;
    margin: 0 auto;
  }

  &__news {
    &Heading {
      display: flex;
      gap: 50px;

      @include mq(tb) {
        flex-direction: column;
        gap: 5px;
      }
    }

    &Item {
      border-top: 1px solid #444444;
      padding: 40px 20px;

      @include mq(tb) {
        padding: 10px;
      }

      &:last-child {
        border-bottom: 1px solid #444444;
      }

      &.is_active {
        .newsSection__newsClose {
          transform: rotate(180deg);
        }
      }
    }

    &Column {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &Description {
      display: none;
    }

    &Close {
      transition: all 0.5s;

      &Btn {
        .fas {
          &:before {
            color: #9FA0A0;
          }
        }
      }
    }
  }
}



/***************************************
一覧ページ
***************************************/
#page_product_list {


  .subVisual {
    img {
      max-width: 100%;
    }
  }

  .ec-layoutRole__main {
    padding: 0 5%;
  }
}

/***************************************
詳細ページ
***************************************/
.ec-productRole {
  &__btn {
    .icon_cart {
      width: 28px;
      height: 30px;
      margin-right: 10px;
    }

    &.orgBtn--cart {
      width: 100% !important;

      button {
        background-color: var(--c-red);
        border-radius: 10px;
      }
    }

    .icon_favo {
      width: 26px;
      height: 22px;
      margin-right: 10px;
    }

    &.orgBtn--favo {
      max-width: 250px !important;
      min-width: 250px !important;

      @include mq(sp) {
        max-width: 100% !important;
        min-width: 100% !important;
      }

      button {
        background-color: var(--c-white);
        border: 1px solid #9FA0A0;
        border-radius: 10px;
        color: #9FA0A0;
      }
    }
  }
}

.seminarInfo {
  &__comment {
    h2 {
      margin-bottom: 20px;
      padding: 10px;
      background-color: #F1F0E6;
      border-left: 5px solid var(--c-red);
      color: var(--c-red);
      font-size: 20px;
    }

    p {
      font-size: 14px;
      margin-bottom: 50px;
      line-height: 1.75;
    }

    ul {
      font-size: 14px;
      margin-bottom: 50px;
      line-height: 1.75;
    }

  }
}


#product_review_area {
  margin-bottom: 100px !important;
  border-top: none !important;

  h4 {
    background-color: #F1F1EE;
    display: flex;
    align-items: center;

    @include mq(sp) {
      font-size: 12px;
    }

    .recommend_average {
      color: var(--c-primary);
    }

    .pull-right {
      margin-left: auto;
    }
  }

  #reviewContent {
    margin-bottom: 30px;
  }

  .ec-inlineBtn--action {
    background-color: var(--c-red);
  }
}